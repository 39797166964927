@import "../../../styles/variables";

.MuiDataGrid-root.app-grid {
  background-color: $window-Background;
  border-radius: 10px;
  height: 90vh;
}

.Textfield-table {
  flex: 1;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.reviewgrid-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  height: 98%;

  &>div {
    flex: 1;
    overflow-x: auto;
  }

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  & ::-webkit-scrollbar-thumb {
    // border-radius: 4px;
    background-color: lightgray;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .border-left,
  .border-right {
    background-color: $box-border-color;
    width: 1px;
    margin-top: 20px;
  }

  .border-left {
    left: 0;
  }

  .border-right {
    right: 0;
  }
}

table.reviewgrid-dashboard {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  color: #333;

  th {
    .heading {
      font-weight: 800;
      font-size: larger;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  tr {

    td {
      padding: 2px !important;
      border: none;
      white-space: nowrap;
      max-width: 300px;
      min-width: 100px;
      overflow: hidden;
      border: 1px solid $box-border-color;

      .displayField {
        text-align: center;
        overflow-y: scroll;
        min-height: 45px;
        margin-top: 0px;
        margin-top: 24px;
      }

      &.fixedColumn {
        position: sticky;
        background-color: $window-Background;
        width: 20px;
        min-width: 0px !important;
        right: 0;
        z-index: 1;
      }

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      .dropdown {
        margin-top: 0px !important;
        width: 200px !important;
      }

      .h-app-input-field {
        margin-top: 0px !important;
      }
    }
  }
}