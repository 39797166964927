@import "./variables";

html {
  background-color: $window-Background;
}

html,
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

body {
  background: $page-Background;
  color: $page-Foreground;
}

#root,
.h-app-container {
  height: 100%;
}

.h-app-container {
  display: flex;
  flex-direction: column;
  transition: filter 0.3s, background 0.5s;

  &.has-cover {
    filter: blur(0px);
  }

  &.signed-out {
    flex-direction: row;
    justify-content: center;
    background: $page-signed-out-Background;
  }
}

.hide-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

hr {
  border: none;
  height: 1px;
  background: $horizontalRule-Background;
  margin: $component-padding 0;
}

.h-app-item-page-content {
  position: absolute;
  left: $library-width + $component-padding;
  top: 0;
  bottom: 0;
  right: 0;
  background: $item-Background;
  color: $item-Foreground;
  animation: $animation-slide-in;
  border-radius: $box-border-radius;
  box-shadow: $layer-shadow;
}
