@import "../../styles/variables";

.h-app-settings-page {
  display: flex;
  justify-content: center;
}

.h-app-settings-page-content {
  position: absolute;
  top: 0;
  left: $component-padding;
  bottom: $component-padding;
  right: $component-padding;
  padding: $component-padding;
}
