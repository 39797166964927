@import "../../styles/variables";

.h-app-button {
  border-radius: $box-border-radius !important;
  padding: $button-padding - 3px !important;
  background: $button-secondary-Background;
  color: $button-secondary-Foreground;
  transition: all 0.2s;
  border-width: 0 !important;
  border-style: solid !important;
  border-color: transparent !important;
  min-width: 60px;

  &.Mui-disabled {
    background: $button-secondary-Background;
    color: $button-secondary-disabled-Foreground;
  }

  &.Mui-focusVisible {
    border-color: $button-seconday-focus-Border !important;

    &.primary {
      border-color: $button-primary-focus-Border !important;
    }
  }

  &:hover {
    background: $button-secondary-hover-Background;
    color: $button-secondary-hover-Foreground;
  }

  &.primary {
    background: $button-primary-Background;
    color: $button-primary-Foreground;

    &:hover {
      background: $button-primary-hover-Background;
      color: $button-primary-hover-Foreground;
    }

    &:active {
      transform: translateY(3px);
      box-shadow: none;
    }

    &.Mui-disabled {
      background: $button-primary-disabled-Background;
      color: $button-primary-disabled-Foreground;
    }
  }

  &.raised {
    box-shadow: $layer-shadow;
  }

  &.capitalize {
    text-transform: uppercase;
  }

  &.working {
    svg {
      animation: spin 1s linear infinite;
    }
  }

  svg {
    margin-right: $component-gutter;

    &.end {
      margin-right: 0;
      margin-left: $component-gutter;
    }
  }
}
