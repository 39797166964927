@import "../../styles/variables";

.h-app-side-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: $sideNav-width;
  margin-bottom: 12px;
  margin-top: 32px;
  margin-left: 8px;
  box-sizing: border-box;
  background: #fff;
  animation: side-nav-in 0.2s forwards ease-in-out;
  box-shadow: $layer-shadow;
  border-radius: 8px;

  .h-app-scrollable-content {
    padding-top: $component-padding;
  }
}

.h-app-side-nav-link {
  display: block !important;
  background: $sideNav-item-Background;
  border-radius: 5px !important;
  margin: $component-gutter;
  display: block;
  text-decoration: none !important;
  color: $sideNav-item-Foreground;
  overflow: hidden;
  animation: side-nav-in 0.5s forwards;

  &:hover {
    background: $sideNav-item-hover-Background;
    color: $sideNav-item-hover-Foreground;
  }

  button {
    padding: 10px !important;
    font-size: 16px !important;
    flex-wrap: wrap;
  }

  svg {
    display: inline-block;
    margin-right: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: 20px;
    color: $toolbar-item-icon-Foreground;
    stroke: $toolbar-item-icon-Foreground;
    fill: $toolbar-item-icon-Foreground;

    path {
      stroke: currentColor !important;
    }
  }

  .description {
    display: block;
    flex: 0 0 100%;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    padding: $component-padding 0 0 30px;
    opacity: 0.6;
  }

  &.back {
    button {
      font-weight: 600 !important;
    }
  }

  &.active {
    background: $sideNav-item-active-Background;
    color: $sideNav-item-active-Foreground;
    box-shadow: $layer-shadow;

    svg {
      color: currentColor;
      stroke: currentColor;
      fill: currentColor;

      path {
        stroke: currentColor !important;
      }
    }
  }

  &.expandable {
    .fa-chevron-down {
      position: absolute;
      right: $component-padding;
      transition: all 0.2s;
    }

    &.expanded {
      background: $sideNav-item-hover-Background;
      color: $sideNav-item-hover-Foreground;

      .fa-chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}

.h-app-side-nav-group {
  overflow: hidden;
  transition: height 0.2s;
  margin: -$component-gutter 0;
  padding-bottom: 5px;

  .h-app-side-nav-link {
    margin-left: 30px + $component-gutter;
  }
}

@keyframes side-nav-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}