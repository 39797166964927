@import "../../styles/variables";

.h-app-profile-page-content {
  position: absolute;
  left: $component-padding;
  top: 0;
  bottom: $component-padding;
  right: $component-padding;
  background: $item-Background;
  color: $item-Foreground;
  animation: item-page-in 0.2s forwards;
  border-radius: $box-border-radius;
  box-shadow: $layer-shadow;

  .profile-page {
    padding: $page-padding;
  }
}
