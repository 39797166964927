@import "../../styles/variables";

.h-service-library-page {
  display: flex;
  justify-content: center;
}

.h-service-library-page-content {
  position: absolute;
  top: 0;
  left: $component-padding;
  bottom: $component-padding;
  right: $component-padding;
  padding: $component-padding;
}

.h-app-item-page-content {
  .h-app-form {
    padding: 5px 20px;

    .h-app-field-instruction-wrapper label {
      margin: auto;
      margin-top: auto !important;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin: $page-padding;
    padding: 0;
    color: $app-primary;
    font-size: 24px;
    font-weight: 500;
  }

  .h-map-view-page-map-wrapper {
    position: revert;

    .mapboxgl-canvas-container {
      height: 500px;

      svg {
        path {
          fill: red;
        }
      }
    }
  }
}
