.confirmed-event {
    background-color: #4caf50;
    /* Green color for confirmed events */
    color: #fff;
    /* Text color for confirmed events */
}

.pending-event {
    background-color: #ff9800;
    /* Orange color for pending events */
    color: #fff;
    /* Text color for pending events */
}

.cancelled-event {
    background-color: #f44336;
    /* Red color for cancelled events */
    color: #fff;
    /* Text color for cancelled events */
}