// Colors
$app-primary: #7cc2e6;
$app-primary-light: #7cc2e6;
$app-primary-dark: #7cc2e6;
$gradient-first-stop: #7cc2e6;
$gradient-last-stop: #7cc2e6;
$clickable-gradient: linear-gradient(135deg,
                $gradient-first-stop,
                $gradient-last-stop);
$window-Background: #fff;
$page-Background: #f2f2f2;
$no-data-Background: #f5f5f5;
$no-data-Foreground: rgba(0, 0, 0, 0.4);
$page-Foreground: #fff;
$page-signed-out-Background: #fff;
$sideNav-Background: transparent;
$sideNav-item-Background: transparent;
$sideNav-item-Foreground: rgba(0, 0, 0, 0.9);
$sideNav-item-hover-Background: #7cc2e6;
$sideNav-item-hover-Foreground: inherit;
$sideNav-item-active-Background: $clickable-gradient;
$sideNav-item-active-Foreground: #fff;
$toolbar-Background: transparent;
$toolbar-Foreground: inherit;
$toolbar-item-icon-Foreground: $app-primary-dark;
$horizontalRule-Background: rgba(3, 2, 2, 0.1);
$scrollbar-Background: rgba(0, 0, 0, 0.2);
$scrollbar-hover-Background: rgba(0, 0, 0, 0.3);
$library-Background: #fff;
$library-Foreground: #333;
$library-item-Background: transparent;
$library-item-Foreground: #333;
$library-item-hover-Background: #7cc2e6;
$library-item-hover-Foreground: inherit;
$library-item-active-Background: $clickable-gradient;
$library-item-active-Foreground: #fff;
$item-index-Background: transparent;
$item-index-Foreground: rgba(0, 0, 0, 0.3);
$item-Background: #fff;
$item-Foreground: #7cc2e6;
$shadow-Color: rgba(50, 50, 50, 0.4);
$field-instructions-Foreground: rgba(0, 0, 0, 0.4);
$field-instructions-Background: #f5f5f5;
$field-instructions-error-Foreground: rgb(230, 0, 0);
$breadcrumb-Background: transparent;
$breadcrumb-Foreground: $app-primary-dark;
$breadcrumb-hover-Background: rgba(255, 255, 255, 0.8);
$breadcrumb-hover-Foreground: $app-primary;
$search-box-Background: #fff;
$search-box-Foreground: #333;
$search-box-hover-Background: #fff;
$search-box-hover-Foreground: #000;
$search-box-hover-Border: #7cc2e6;
$search-box-focus-Background: #fff;
$search-box-focus-Foreground: #000;
$search-box-focus-Border: $app-primary;
$paging-Background: transparent;
$paging-selected-Background: $app-primary;
$paging-selected-Foreground: #fff;
$field-readonly-Foreground: rgba(0, 0, 0, 0.87);
$field-readonly-Border: rgba(0, 0, 0, 0.08);
$button-primary-Background: $app-primary;
$button-primary-Foreground: #fff;
$button-primary-hover-Background: $app-primary-light;
$button-primary-hover-Foreground: #fff;
$button-primary-disabled-Background: #ddd;
$button-primary-disabled-Foreground: #fff;
$button-primary-focus-Border: #fff;
$button-secondary-Background: transparent;
$button-secondary-Foreground: #404041;
$button-secondary-hover-Background: rgba(255, 255, 255, 0.8);
$button-secondary-hover-Foreground: #404041;
$button-secondary-disabled-Background: transparent;
$button-secondary-disabled-Foreground: rgba(0, 0, 0, 0.2);
$button-seconday-focus-Border: #fff;
$modal-window-Background: #eee;
$modal-content-Background: #7cc2e6;
$error-Background: #f44336;
$error-Foreground: #7cc2e6;
$warning-Background: #ffa726;
$warning-Foreground: #7cc2e6;
$info-Background: #66bb6a;
$info-Foreground: #7cc2e6;
$unknown-Background: $app-primary;
$unknown-Foreground: #333;
$module-component-Background: #7cc2e6;
$subtitle-Background: rgba(0, 0, 0, 0.05);
// Sizing
$sideNav-width: 280px;
$page-gutter: 20px;
$page-padding: 20px;
$component-gutter: 5px;
$component-padding: 10px;
$library-width: 320px;
$field-gutter: 30px;
$form-padding: $page-padding;
$app-logo-margin: 15px;
$button-padding: 10px;
// Animations
$animation-fade-in: fade-in 0.2s forwards;
$animation-slide-in: slide-in 0.4s forwards;
// Other layout
$layer-shadow: 0 3px 7px $shadow-Color;
$box-border-radius: 5px;
$box-border-color: rgba(0, 0, 0, 0.08);