@import "../../styles/variables";

.h-app-booking {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $item-index-Foreground;
  animation: $animation-fade-in;

  svg {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.h-booking-page {
  display: flex;
  justify-content: center;
}

.h-booking-page-content {
  position: absolute;
  top: 0;
  left: $component-padding;
  bottom: $component-padding;
  right: $component-padding;
  padding: $component-padding;

  .library {
    height: 86%;
  }
}

.booking-header {
  display: flex;
  justify-content: space-between;

  h2 {
    color: $app-primary;
    margin-left: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 500;
  }
}

.btn-header {
  width: 120px;
  height: 40px;
  align-self: center;
  margin-right: 20px !important;
}

/* Calendar Container */
.calendar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;

}

/* Custom Calendar Styles */
.custom-calendar {
  width: 100%;
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 400px;

  .confirmed-event {
    background-color: #4caf50;
    /* Green color for confirmed events */
    color: #fff;
    /* Text color for confirmed events */
  }

  .pending-event {
    background-color: #ff9800;
    /* Orange color for pending events */
    background-color: #fff;
    /* Text color for pending events */
  }

  .cancelled-event {
    background-color: #f44336;
    /* Red color for cancelled events */
    color: #fff;
    /* Text color for cancelled events */
  }

  .selected-event {
    /* Your existing styles for selected events */
    border: 2px solid #2196f3;
    /* Blue border for selected events */
  }

}



.react-calendar__tile--active {
  background-color: $app-primary;
  color: white;
}

.react-calendar__tile--hover {
  background-color: #fff;
  cursor: pointer;
}

/* Booking List Styles */
.booking-list {
  width: 90%;
  padding: 24px;
  margin: 24px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.booking-list h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.booking-list ul {
  list-style-type: none;
  padding: 0;
}

.booking-list li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.4;
  color: #333;
}


.h-app-item-page-content {
  .h-app-form {
    padding: 5px 20px;

    .h-app-field-instruction-wrapper label {
      margin: auto;
      margin-top: auto !important;
    }
  }



  .pager {
    border-top: solid 1px $horizontalRule-Background;
    padding: $component-padding;
    background: $paging-Background;

    &>ul {
      justify-content: center !important;

      .Mui-selected {
        background: $paging-selected-Background !important;
        color: $paging-selected-Foreground !important;
      }
    }
  }


  .loading {
    opacity: 0.7;
    padding: $component-padding;
    text-align: center;
    display: block;
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin: $page-padding;
    padding: 0;
    color: $app-primary;
    font-size: 24px;
    font-weight: 500;
  }
}

.event-details {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-details h2 {
  font-size: 18px;
  padding-left: 24px;
  color: #333;
  margin: 24px auto;
}

.event-details p {
  padding-left: 24px;
  margin: 24px auto;
  font-size: 16px;
  color: #555;
}