@import "../../../styles/variables";

.review-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.review-card {
  background-color: $window-Background;
  border-radius: 10px;
  padding: 20px;
  box-shadow: $layer-shadow;
  display: flex;
  flex-direction: column;
  position: relative;

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-bottom: 10px;

    .date {
      display: flex;
      font-weight: 500;
      font-size: medium;
      color: $app-primary;
    }

    .checkbox {
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;

      input[type="checkbox"] {
        margin: 10px;
        width: 20px;
        height: 20px;
        color: $app-primary;
      }

      :hover {
        color: grey;
      }
    }
  }

  .middle-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .heading {
      font-weight: 800;
      font-size: larger;
      padding-bottom: 10px;
      color: #333;
    }

    .sub-heading {
      font-weight: 500;
      font-size: medium;
      color: #333;
    }

    .review-text {
      color: #333;
      max-height: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;
      padding-bottom: 10px;
    }
  }

  .grey-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: grey;
  }

  .bottom-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .rating-stars {
      .star-filled {
        color: rgb(212, 212, 19); // Change the color of filled stars
      }

      .star-empty {
        color: grey; // Change the color of empty stars
      }
    }
  }
}