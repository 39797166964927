@import "../../styles/variables";

.reviewmain-dashboard-page.h-app-item-page-content {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  left: 294px !important;

  &>div {
    flex: 1;
  }

  &>nav {
    flex: 0 0 50px;
  }

  .review-main-header {
    display: flex;
    justify-content: space-between;
    margin-right: 24px;
    margin-left: 24px;

  }
}

.list-continer {
  display: flex;
  border: 3px solid #ededed;
  border-radius: 10px;
  align-content: space-between !important;
  margin: 20px;
}

.review-main-dashboard {
  display: flex;
  align-items: center;
  width: 90%;

  .h-app-input-field {
    margin-top: 0 !important;
  }

  .h-app-search-box {
    width: 10% !important;
  }

  .list-item {
    width: 100% !important;
    margin: 5px !important;
  }
}

.btn-style {
  height: 40px;
  align-self: center;
  width: 100px;

  &.clear {
    background-color: lightgray;
    margin-left: 5px;
    margin-right: 5px;
    width: 5px;
  }
}

.h-company-library-page {
  display: flex;
  justify-content: center;
}

.h-company-library-page-content {
  position: absolute;
  top: 0;
  left: $component-padding;
  bottom: $component-padding;
  right: $component-padding;
  padding: $component-padding;
}

.h-app-item-page-content {
  .h-app-form {
    padding: 5px 20px;

    .h-app-field-instruction-wrapper label {
      margin: auto;
      margin-top: auto !important;
    }
  }

  h1 {
    margin-bottom: 0;

  }

  h2 {
    margin: $page-padding;
    padding: 0;
    color: $app-primary;
    font-size: 24px;
    font-weight: 500;
  }

  .h-map-view-page-map-wrapper {
    position: revert;

    .mapboxgl-canvas-container {
      height: 500px;

      svg {
        path {
          fill: red;
        }
      }
    }
  }
}