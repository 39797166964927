@import "../../styles/variables";

.h-app-field-flex-layout {
  display: flex;
  flex-direction: row;
  margin: $field-gutter 0;

  .h-app-input-field {
    flex: 1 !important;
    margin: 0 !important;

    &:not(:last-child) {
      margin-right: $field-gutter !important;
    }
  }
}

.h-app-input-field {
  display: block !important;
  margin-bottom: 0 !important;
  margin-top: $field-gutter !important;

  .MuiInputBase-root {
    width: 100% !important;
  }

  .MuiInputBase-adornedStart {
    svg {
      margin-right: $component-padding;
    }

    &.Mui-focused {
      svg {
        color: $app-primary;
      }
    }
  }

  .MuiInputBase-adornedEnd {
    .h-app-button {
      height: 33px;
      margin: 1px 2px 1px 0;
    }
  }

  fieldset {
    transition: border-color 0.2s;
    border-width: 2px;
  }

  &.readonly * {
    cursor: default !important;

    fieldset {
      border-color: $field-readonly-Border !important;
    }
  }

  .library-link {
    margin-right: -10px;
    border-radius: $box-border-radius;
    overflow: hidden;

    button {
      background: $button-primary-Background;
      color: $button-primary-Foreground;
      padding: $component-padding - 2px !important;
      cursor: pointer !important;
      transition: all 0.2s;
      text-transform: uppercase;

      &:hover {
        background: $button-primary-hover-Background;
        color: $button-primary-hover-Foreground;
      }

      svg {
        margin: 0 $component-gutter;
      }
    }
  }
}

.h-app-autocomplete-field {
  &.readonly * {
    cursor: default !important;

    fieldset {
      border-color: $field-readonly-Border !important;
    }
  }
}

.h-app-date-field {
  &.readonly {
    .MuiInputAdornment-root {
      color: $field-readonly-Border !important;
    }
  }
}

.h-app-checkbox {
  margin-top: $field-gutter - 10px !important;

  .MuiCheckbox-root {
    margin-bottom: 1px;
  }

  &.readonly {
    .MuiFormControlLabel-label {
      color: $field-readonly-Foreground !important;
    }

    .Mui-checked {
      color: $app-primary !important;
    }
  }
}

.h-app-number-field {
  .currency-symbol {
    display: block;
    margin-right: $component-gutter;
    opacity: 0.5;
    font-weight: 500;
  }
}

.h-app-radio-buttons {
  &.readonly {

    label,
    .MuiFormControlLabel-label {
      color: $field-readonly-Foreground !important;
    }

    .Mui-checked {
      color: $app-primary !important;
    }
  }
}

.h-app-file-upload {
  label {
    &.Mui-focused {
      margin-top: 0;
    }
  }

  button {
    width: 60px;
    flex: 0 0 60px;

    &:active {
      transform: none !important;
    }
  }

  .upload-component {
    padding: 8.5px 14px;
    background: red;
  }

  .MuiOutlinedInput-root {
    padding-right: 2px !important;
  }

  .clear-button {
    border-radius: 50% !important;
    padding: 0;
    min-width: unset;
    flex: 0 0 30px;
    height: 30px !important;

    svg {
      margin: 0;
    }
  }

  .file-icon {
    height: 22px;
  }

  .excel-file {
    color: rgba(0, 180, 0);
  }

  .word-file {
    color: rgba(0, 120, 230);
  }

  .text-file {
    color: rgba(150, 110, 0);
  }

  .pdf-file {
    color: rgba(210, 0, 0);
  }

  .image-file {
    color: rgba(0, 180, 150);
  }

  .video-file {
    color: rgba(150, 0, 180);
  }

  .audio-file {
    color: rgb(220, 150, 0);
  }

  .archive-file {
    color: rgb(241, 204, 38);
  }
}

.h-app-field-instruction-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-bottom: dashed 1px $horizontalRule-Background;

  .instruction-field {
    flex: 1.5;
    margin-right: $field-gutter;
    margin-bottom: $field-gutter;

    &.flex-row {
      display: flex;
      flex-direction: row;

      &>* {
        flex: 1;

        &:not(:last-child) {
          margin-right: $component-padding;
        }
      }
    }
  }

  .instruction-text {
    flex: 1;
    padding: 0 $field-gutter;
    background: $field-instructions-Background;
    color: $field-instructions-Foreground;
    font-size: 14px;
    align-self: stretch;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .constraints {
      display: block;
      margin-top: $component-gutter;
      font-size: 12px;
      font-weight: 700;
    }

    .error {
      display: block;
      color: $field-instructions-error-Foreground;
      margin-top: $component-gutter;
      font-size: 12px;
    }
  }

  &:first-of-type {
    .instruction-text {
      border-top-right-radius: 7px;
    }
  }

  &:last-of-type {
    border-bottom: none;

    .instruction-text {
      border-bottom-right-radius: 7px;
    }
  }
}

.option-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;

  .option-field {
    width: 100%;
    padding-right: 10px;
    margin-top: 0 !important;
  }

  .option-adder {
    margin-top: 3px;
    height: 20px;
    background: linear-gradient(135deg, #4499ff, #22bb77);
    color: #fff;
  }
}

.h-app-search-and-chips-field {
  .chips {
    margin-top: $field-gutter - $component-gutter !important;
    overflow: visible;

    .chip {
      border-radius: $box-border-radius;
      background: $button-primary-Background;
      color: $button-primary-Foreground;
      box-shadow: $layer-shadow;
      padding: $component-padding 25px $component-padding $component-padding;
      display: inline-block;
      margin-right: $component-gutter;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
      font-size: 12px;
      position: relative;

      &.no-data {
        display: inline-block;
        padding-right: $component-padding;
        background: $no-data-Background;
        color: $no-data-Foreground;
        margin: 2px 0;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
        max-width: unset;
        text-align: center;
      }

      &.read-only {
        padding-right: $component-padding;
      }

      .delete-chip {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 25px;
      }
    }
  }
}