@import "../../styles/variables";

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &.dark {
    background-color: $window-Background;
    /* Define the dark background color variable */
    box-shadow: none;
  }

  .inner {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;

    /* You can define these values as variables */
    @media (min-width: 768px) {
      padding: 1rem 3rem;
      /* Adjust for medium screens */
    }

    @media (min-width: 1280px) {
      padding: 1rem 6rem;
      /* Adjust for large screens */
    }

    .menu-button {
      /* Hamburger Toggle Button Styles */
      // Your existing styles for the button
    }

    .logo-link {

      // Styles for the logo link
      .logo-image {
        // Styles for the logo image
      }
    }

    .search-form {

      // Styles for the search form
      .search-icon {
        // Styles for the search icon
      }

      .search-input {

        // Styles for the search input
        &::placeholder {
          // Styles for the placeholder text
        }

        &:focus {
          // Styles for the input when focused
        }
      }
    }

    .menu-items {
      // Styles for the menu items
    }

    .user-area {
      // Styles for the user area
    }
  }
}

/* dark-mode-switcher.scss */
.dark-mode-switcher {
  // Styles for the dark mode switcher
}

/* dropdown-notification.scss */
.dropdown-notification {
  // Styles for the notification dropdown
}

/* dropdown-message.scss */
.dropdown-message {
  // Styles for the message dropdown
}

/* dropdown-user.scss */
.dropdown-user {
  // Styles for the user dropdown
}