@import "../../styles/variables";

.h-page-body {
  margin: 0 0 0 $sideNav-width;
  transform: translate(0);
  flex: 100;

  .index,
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $item-index-Background;
    color: $item-index-Foreground;
    animation: none;
    box-shadow: none;
    animation: $animation-fade-in;

    svg {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
}